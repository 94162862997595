import React from 'react';
import { Badge, message, Modal } from 'antd';
import * as Sentry from '@sentry/react';
import { EErrorType } from '../utils/constant';
// import { debounce } from 'lodash';

export function onError(e: any, dispatch: any) {
  // 打印错误信息，包括错误栈
  console.error('🚀 ~ file: onError.js ~ line 5 ~ onError ~ e', e);
  e.preventDefault && e.preventDefault();

  /* 错误信息中不包含message则直接打印错误信息 */
  if (!e.message) {
    /* eslint-disable */
    console.error(e);
    return;
  }

  // 判断错误类型,当为js错误的时候上报sentry
  if (e.type !== EErrorType.Request) {
    Sentry.captureException(e);
    return;
  }

  const { message: messageText, code } = e;

  /* 按照错误类型展示相应类型的错误提示（modal类型与message类型） */
  if (e.isModalError) {
    // Modal.error({
    //   title: code,
    //   content: messageText,
    // });
    getErrorTip(code, messageText, 'modal')();
  } else {
    const messageContent = code ? `[${code}]${messageText}` : messageText;
    // message.error(messageContent);
    getErrorTip(code, messageContent, 'message')();
  }
}

const errorCountMap: Map<number | string, number> = new Map();

function getErrorTip(code: number | string, msg: string, type: 'modal' | 'message') {
  console.log("🚀 ~ file: onError.tsx ~ line 44 ~ getErrorTip ~ code", code)
  let errorCount = errorCountMap.get(code) || 0;
  const errorFunc = () => {
    if (type === 'modal') {
      Modal.error({
        title: code,
        content: msg,
      });
    } else {
      message.error({
        content: (
          <Badge
            title={`错误数量${errorCount}`}
            count={errorCount}
            offset={[0, 8]}
            style={{ backgroundColor: '#FEFE24', color: '#000' }}
          ><span style={{paddingRight: errorCount > 1 ? 16 : 0}}>{msg}</span></Badge>
        ),
        key: code,
        onClose: () => {
          errorCountMap.set(code, 0);
        }
      });
    }
  }
  errorCountMap.set(code, ++errorCount);
  return errorFunc
}
