import { addInfoEffect, BaseModel, getInfoEffect } from "../utils/common";
import urlMaps from "../config/urlMaps";

export class AppState {
  list = [1, 2, 3, 4];
  netStatus: boolean = true;
  netErrorJumpTargetRoute: string = '';
  tokenInfo = {
    'sign-token': '',
  };
}

export class AppModel extends BaseModel<AppState>  {
  namespace: 'app' = 'app';
  state: AppState = new AppState();
  effects = {
    userInfo: getInfoEffect<any>(urlMaps.userAuthList),
    getToken: addInfoEffect<any>(urlMaps.getToken, 'tokenInfo'),
  };
}

export const appModelInstance = new AppModel();
