import { BaseModel, getListEffect } from "../utils/common";
import urlMaps from "../config/urlMaps";

export class NewYoBoState {
  overview = [];
}

export class NewYoBoModel extends BaseModel<NewYoBoState>  {
  namespace: 'newYoBo' = 'newYoBo';
  state: NewYoBoState = new NewYoBoState();
  effects = {
    overview: getListEffect<any>(urlMaps.newYoBoPerformanceOverview, 'overview'),
  };
}

export const newYoBoModelInstance = new NewYoBoModel();
