import React from 'react'
import { ConfigProvider } from 'antd'
import './App.less'

import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import { Layouts } from './layouts'
import { RouterAPI } from 'dva'
import { RaProvider, RaStatReportProvider, setUserAuthList, specifySetModuleKey } from 'radish'
// import 'radish/build/radish-csx-antd.css';

moment.locale('zh-cn');

interface IProps {
  api: RouterAPI | undefined;
}

specifySetModuleKey(function(props) {
  return `/bbc__${props.btnKey}`;
});
setUserAuthList([
  {
    'btnKey':"download",
    'btnName':"下载",
    'exportName':"NewYoBoExportService",
    'isAble':"1",
    'isDeleted':"0",
    'operationId':"1",
    'type':"1",
    'url':"/bbc",
    'viewId':"1",
    'key':"/bbc__download",
  } as any
]);

export function App(props: IProps) {
  return (
    <ConfigProvider locale={zhCN}>
      <RaProvider
          pageUrl="/bbc"
          csxAntPrefixCls="ant"
          // feat={{
          //   url: 'http://api.b2bcsx.com/devops-v1/devops/txSwitch2C/queryByProject',
          //   reqData: {
          //     env: featEnv,
          //     projectId: 381, //北极星数据中心前端项目id
          //     type: 1, //前端
          //   },
          // }}
        >
          <RaStatReportProvider pageUrl='/bbc'>
            <Layouts></Layouts>
          </RaStatReportProvider>
        </RaProvider>
    </ConfigProvider>
  )
}
