import React from 'react'
import './index.css'
import {App} from './App'
import dva, { RouterAPI } from 'dva'
import {delay} from 'lodash'
import {models} from './models'
import createLoading from 'dva-loading';
import { onError } from './plugins/onError'
import * as Sentry from '@sentry/react';
import { ENV, RELEASE_VERSION } from './hosts'
import { setStatInfo } from 'radish';
import { logger } from 'radish/build/utils/Logger';
import { reportException } from './utils/report'
import store from 'store';

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// )
setStatInfo({
  projectName: 'fe-web-exterior-data-service',
  interval: 10,
  statUrl: 'http://dev.data.b2bcsx.com:10087/api/component-stat/report',
});


logger.rewriteReport({
  errorReport: (...args: any) => {
    logger.error(...args);

    const env = ENV;
    const userInfos = store.get('userInfos') || {};

    const href = window.location.href;
    let lastException = '\n<br/>';
    // lastException += '【页面名称】: ' + title + '\n<br/>';
    lastException += '【来源】: <a href="' + href + '" target="_blank">' + href + '</a>\n<br/>';
    // lastException += '【data】: ' + dataStr + '\n<br/>';
    // lastException += '【异常标题】: ' + message + '\n<br/>';
    lastException += '【用户信息】: <pre>' + JSON.stringify(userInfos, null, 2) + '</pre>\n';
    lastException +=
      '【UserAgent】: <pre>' + JSON.stringify(window.navigator.userAgent, null, 2) + '</pre>\n';
    lastException += '【异常栈】: <pre>' + JSON.stringify(args, null, 2) + '</pre>\n';

    let exceptionLevel = 1;

    if (env === 'production') {
      exceptionLevel = 0;
    }

    reportException({
      exceptionCode: '150620001',
      /** 异常标题，必填，非空 */
      exceptionTitle: 'radish组件库异常',
      /** 异常内容 */
      exceptionContent: lastException,
      /** 异常级别 0 通知 3 极其严重  2 严重  1 一般  4灾难（打电话） */
      exceptionLevel: exceptionLevel,
      /** 0,  异常类型：1 BSF、1后端、2前端、3业务、4运维、5DBA、6其他 */
      exceptionType: 2,
      /** 操作人 */
      operator: userInfos.userId,
    });
  },
});

if (ENV !== 'development') {
  Sentry.init({
    dsn: 'https://d6800922187f4e27af9926470cae3964@appsentry.freshfood.cn/75',
    environment: ENV,
    release: `${RELEASE_VERSION}`,
  });
}


const app = dva({
  onError: onError,
  onHmr: () => {}
});

app.use(createLoading());

models.forEach((item: any) => {
  app.model(item);
})

// 注册 Model
app.model({
  namespace: 'count',
  state: 0,
  reducers: {
    add(state) { return state + 1 },
  },
  effects: {
    *addAfter1Second(action, { call, put }) {
      yield call(delay, 1000);
      yield put({ type: 'add' });
    },
  },
});

// 注册视图
app.router((api: RouterAPI | undefined) => <App api={api} />);
// app.router(() => <App />);

start();

// 启动应用
export function start() {
  app.start('#root');
}
