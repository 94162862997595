import React, { FunctionComponent } from 'react';
import { tmpRoute } from './tmpRoute';
//dynamic-import

interface IRouteItem {
  url: string;
  component: typeof React.Component | FunctionComponent | React.LazyExoticComponent<any>;
  meta: {
    disabledOuterScroll?: boolean;
    title: string;
    icon?: string;
    [key: string]: any;
  };
  children?: IRouteItem[];
}

export const demoRouterData: IRouteItem[] = [
  // {
  //   url: '/dc/demo',
  //   component: Demo,
  //   meta: {
  //     title: 'Demo首页',
  //   },
  // },

];

const routerData: IRouteItem[] = [
  ...tmpRoute,
  ...demoRouterData,
  {
    url: '/404',
    component: React.lazy(() => import('../pages/NotFound/NotFound')),
    meta: {
      title: '404',
      icon: 'home',
    },
  },
  {
    url: '/500',
    component: React.lazy(() => import('../pages/ServerError/ServerError')),
    meta: {
      title: '500',
      icon: 'home',
    },
  },
  {
    url: '/bbc',
    component: React.lazy(() => import('../pages/BbcAgreeAnalysis')),
    meta: {
      title: 'bbc',
      icon: 'home',
    },
  },
  {
    url: '/newYoBo',
    component: React.lazy(() => import('../pages/NewYoBo')),
    meta: {
      title: '牛约堡-项目履约分析',
      icon: 'home',
    },
  },
  {
    url: '/test',
    component: React.lazy(() => import('../pages/Test')),
    meta: {
      title: 'test',
      icon: 'home',
    },
  },

  //dynamic-config

];





export const routeRelation = routerData.reduce((prev: any, item: any) => {
  prev[item.url] = item.purl || item.url;
  return prev;
}, {});

export function getRouteInfo(route: string) {
  return routerData.find(item => item.url === route);
}

export function getParentRoute(currRoute: string) {
  return routeRelation[currRoute];
}

export function getRouterData() {
  return routerData;
}

export default getRouterData;
