import { BaseModel, getListEffect } from "../utils/common";
import urlMaps from "../config/urlMaps";

export class BbcAgreeAnalysisState {
  overview = [];
}

export class BbcAgreeAnalysisModel extends BaseModel<BbcAgreeAnalysisState>  {
  namespace: 'bbcAgreeAnalysis' = 'bbcAgreeAnalysis';
  state: BbcAgreeAnalysisState = new BbcAgreeAnalysisState();
  effects = {
    overview: getListEffect<any>(urlMaps.bbcAgreeAnalysisOverviewV2, 'overview'),
  };
}

export const bbcAgreeAnalysisModelInstance = new BbcAgreeAnalysisModel();
