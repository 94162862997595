
export const devHosts = {
  'data-center': (window as any).DC_HOST || 'http://dev.api.b2bcsx.com:8000/data-hub', // test

  crm: 'http://dev.api.b2bcsx.com:8000/data-hub',

}

export const testHosts = {
  'data-center': (window as any).DC_HOST || 'http://dev.api.b2bcsx.com:8000/data-hub', // test

  // crm: 'http://10.19.238.163:8531/report/api',
}


export const sitHosts = {
  'data-center': (window as any).DC_HOST || 'http://sit.api.b2bcsx.com/data-hub', // sit

  // crm: 'http://10.19.238.163:8531/report/api',
}


export const preHosts = {
  'data-center': `http://pre.kong.b2bcsx.cn:8000/data-hub`, // prod

  crm: 'http://pre.kong.b2bcsx.cn:8000/data-hub',
}

export const prodHosts = {
  'data-center': `https://wapi.freshfood.cn/data-hub`, // prod

  crm: 'https://wapi.freshfood.cn/data-hub',
}



export let hosts: any;

let env: any;

try {
  env = process?.env;
  console.log("🚀 ~ file: index.ts ~ line 63 ~ process", process)
} catch (error) {
  // env = import.meta.env; //TODO cra模式要注释
}
const mode = env.VITE_APP_ENV || env.REACT_APP_ENV;

export const ENV = mode;
export const RELEASE_VERSION = 'v2';


if(ENV === 'development') {
  hosts = devHosts;
} else if(ENV === 'test') {
  hosts = testHosts;
} else if(ENV === 'sit') {
  hosts = sitHosts;
} else if(ENV === 'pre') {
  hosts = preHosts;
} else if(ENV === 'production') {
  hosts = prodHosts;
}
console.log("🚀 ~ file: index.ts ~ line 71 ~ hosts", hosts)
