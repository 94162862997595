import React from 'react'
import styles from './ServerError.module.less'
import { FrownOutlined } from '@ant-design/icons'


export default function ServerError() {
  return (
    <div className={styles.container}>
      <FrownOutlined className={styles.icon} />
      {/* <Icon type="frown" className={styles.icon} /> */}
      <h1 className={styles.text}>登录超时或暂无权限，请重新登录或联系管理员！</h1>
    </div>
  );
}
