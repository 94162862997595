import { cloneDeep } from 'lodash';
// import { UmiLoading } from 'typing';
// import { FormComponentProps } from '@ant-design/compatible/lib/form';
// import { FormComponentProps } from 'antd'
import { FormComponentProps } from 'antd/es/form';

import { EffectsCommandMap, SubscriptionAPI } from 'dva';
import { match } from 'react-router';
import { Action } from 'redux';
import request from './request';
import { RaTableColumnProps } from 'radish';
import { AppState } from '../models/App';
import { PaginationProps } from 'antd/lib/pagination/Pagination';

export class PageData<T> {
  title: RaTableColumnProps<T>[] = [];
  list: T[] = [];
  total: number = 0;
  pagination: PaginationProps | false = false;
}

export interface ResData<T> {
  code: number;
  message: string;
  data: T;
}

export interface ResDataList<T> {
  code: number;
  message: string;
  data: T[];
}

export interface ResDataPage<T> {
  code: number;
  message: string;
  data: PageData<T>;
}

export interface ReqPageData {
  pageIndex: number | string
  pageSize: number | string
}

export interface CommonInfo {
  [key: string]: any
}

export interface CommonPayload {
  [key: string]: any
}

export interface CommonModelProps {
  loading: any;
  app: AppState;
}

export interface CommonAction<Type, Payload, ResData> extends Action<Type> {
  payload?: Payload;
  /** @deprecated 不建议使用， 请使用handleResData */
  handleTitle?: (res: ResDataPage<any>['data']['title']) => ResDataPage<any>['data']['title'];
  /** 处理接口响应数据，再放到model之前 */
  handleResData?: (res: ResData) => ResData;
  /** 非200000，处理错误逻辑 */
  handleError?: (res: ResData) => void;
  /** 200000处理正确逻辑 */
  callback?: (res: ResData) => void;
}
export interface RouteComponentProps<Params>
  extends FormComponentProps, SubscriptionAPI {
  match: match<Params>;
  location: Location & {
    query: Params
  };
}

export interface PageProps<Params>
  extends FormComponentProps, Omit<SubscriptionAPI, 'dispatch'> {
  app: AppState;
  match: match<Params>;
  location: Location & {
    query: Params
  };
}

export interface ModelProps<BusinessModel extends BaseModel<any>>
  extends ModelState<BusinessModel['state'], BusinessModel['namespace']> {
  dispatch: Dispatch<Actions<BusinessModel['effects'], BusinessModel['namespace']>>;
  loading: {
    effects: EffectLoading<BusinessModel['effects'], BusinessModel['namespace']>
  }
}

// 模版用
type TmpObj = {
  tmp: string
}

export type ModelState<Model, stateName> = {
  [K in keyof TmpObj as `${stateName & string}`]: Model
}

type Dispatch<T> = {
  (action: T[keyof T]): void
}

type Actions<BusinessEffects extends Effects, ModelName = ''> = {
  [Property in keyof BusinessEffects]: Omit<Parameters<BusinessEffects[Property]>[0], 'type'> & Action<AddPrefix<Property, ModelName>>
}

type EffectLoading<Effects, ModelName = ''> = {
  [K in keyof Effects as AddPrefix<K, ModelName>]: boolean
}

type AddPrefix<Key, Prefix> = `${Prefix & string}/${Key & string}`

interface CommonEffect<type, Payload, ResData> {
  (
    { callback, payload, handleResData, handleError }: CommonAction<type, Payload, ResData>,
    { call, put }: EffectsCommandMap
  ): Generator<any, void, ResData>
}

export interface ReducerAction<Type = any> extends Action<Type> {
  optType?: string;
  payload: {[key: string]: any};
}

export interface ReducerPageDataAction extends ReducerAction<'pageData'> { }
export interface ReducerListDataAction extends ReducerAction<'listData'> { }
export interface ReducerInfoDataAction extends ReducerAction<'infoData'> { }
export interface ReducerPrimitiveDataAction extends ReducerAction<'primitiveData'> { }

export interface Effects {
  [key: string]: CommonEffect<any, CommonPayload, ResData<any>>
}

export class BaseModel<State extends { [key: string]: any }> {
  namespace = ''
  state!: State
  effects = {}
  // reducers = getCommonReducer()
  reducers = {
    ...getCommonReducer<State>(),
    reset: (state: State, { payload, optType = 'page' }: ReducerAction) => {
      return {
        ...this.defaultState
      }
    },
  }

  defaultState!: State
  resetState() {
    this.state = cloneDeep(this.defaultState)
  }
  setDefaultState(defaultState?: State) {
    this.defaultState = cloneDeep(defaultState || this.state)
  }
}

/**
 *
 * @param url 接口地址
 * @param optType state字段，如果需要
 * @desc 添加handleResData，处理后端不符合要求的数据，同时避免在react组件中处理影响性能
 */
export function getPageEffect<Info, Payload extends ReqPageData = any>(url: string, optType?: string) {
  const page: CommonEffect<any, Payload, ResDataPage<Info>> = function* page(
    { callback, payload, handleTitle, handleResData, handleError },
    { call, put }
  ) {

    const { pageSize, pageIndex: current } = payload as Payload;

    let res: ResDataPage<Info>;

    try {
      res = yield call(request, {
        url: url,
        query: payload,
      });
    } catch (errorRes: any) {
      handleError && handleError(errorRes);
      throw errorRes;
    }

    if (handleResData) {
      res = handleResData(res)
    }

    let {
      data: { title } = {},
    } = res;

    if (handleTitle) {
      title = handleTitle(title as any);
    }

    const pageDataAction: ReducerPageDataAction = {
      type: 'pageData',
      optType: optType,
      payload: {
        ...res.data,
        list: res.data.list || [],
        title: title || [],
        pagination: {
          current,
          pageSize,
          total: res.data.total,
        },
      },
    }

    if (optType) {
      yield put(pageDataAction);
    }

    if (callback) {
      callback(res);
    }
  };

  return page
}
export function getListEffect<Info, Payload = any>(url: string, optType?: string, sort: boolean = true) {
  return function* list(
    { callback, payload, handleResData, handleError }: CommonAction<any, Payload, ResDataList<Info>>,
    { call, put }: EffectsCommandMap
  ) {
    let res: ResDataList<Info>;

    try {
      res = yield call(request, {
        url: url,
        query: payload,
        sort: sort,
      });
    } catch (errorRes: any) {
      handleError && handleError(errorRes);
      throw errorRes;
    }

    if (handleResData) {
      res = handleResData(res)
    }

    if (optType) {
      const listDataAction: ReducerListDataAction = {
        type: 'listData',
        optType: optType,
        payload: res.data,
      }
      yield put(listDataAction);
    }

    if (callback) {
      callback(res);
    }
  };
}

export function getInfoEffect<Info, Payload = any>(url: string, optType?: string) {
  return function* info(
    { callback, payload, handleResData, handleError }: CommonAction<any, Payload, ResData<Info>>,
    { call, put }: EffectsCommandMap
  ) {

    let res: ResData<Info>;

    try {
      res = yield call(request, {
        url: url,
        query: payload,
      });
    } catch (errorRes: any) {
      handleError && handleError(errorRes);
      throw errorRes;
    }

    if (handleResData) {
      res = handleResData(res)
    }

    if (optType) {
      const infoDataAction: ReducerInfoDataAction = {
        type: 'infoData',
        optType: optType,
        payload: res.data,
      }
      yield put(infoDataAction);
    }

    if (callback) {
      callback(res);
    }
  };
}

export function getPrimitiveEffect<Primitive, Payload = any>(url: string, optType?: string) {
  return function* info(
    { callback, payload, handleResData, handleError }: CommonAction<any, Payload, ResData<Primitive>>,
    { call, put }: EffectsCommandMap
  ) {

    let res: ResData<Primitive>;

    try {
      res  = yield call(request, {
        url: url,
        query: payload,
      });
    } catch (errorRes: any) {
      handleError && handleError(errorRes);
      throw errorRes;
    }

    if (handleResData) {
      res = handleResData(res)
    }

    if (optType) {
      const primitiveDataAction: ReducerPrimitiveDataAction = {
        type: 'primitiveData',
        optType: optType,
        payload: res.data,
      }
      yield put(primitiveDataAction);
    }

    if (callback) {
      callback(res);
    }
  };
}

export function addInfoEffect<Info, Payload = any>(url: string, optType?: string) {
  return function* info(
    { callback, payload, handleResData, handleError }: CommonAction<any, Payload, ResData<Info>>,
    { call, put }: EffectsCommandMap
  ) {
    let res: ResData<Info>;

    try {
      res = yield call(request, {
        method: 'POST',
        url: url,
        data: payload,
      });
    } catch (errorRes: any) {
      handleError && handleError(errorRes);
      throw errorRes;
    }

    if (handleResData) {
      res = handleResData(res)
    }

    if (optType) {
      const infoDataAction: ReducerInfoDataAction = {
        type: 'infoData',
        optType: optType,
        payload: res.data,
      }
      yield put(infoDataAction);
    }

    if (callback) {
      callback(res);
    }
  };
}

export function updateInfoEffect<Info, Payload = any>(url: string, optType?: string) {
  return function* info(
    { callback, payload, handleResData, handleError }: CommonAction<any, Payload, ResData<Info>>,
    { call, put }: EffectsCommandMap
  ) {
    let res: ResData<Info>;

    try {
      res = yield call(request, {
        method: 'PUT',
        url: url,
        data: payload,
      });
    } catch (errorRes: any) {
      handleError && handleError(errorRes);
      throw errorRes;
    }

    if (handleResData) {
      res = handleResData(res)
    }

    if (optType) {
      const infoDataAction: ReducerInfoDataAction = {
        type: 'infoData',
        optType: optType,
        payload: res.data,
      }
      yield put(infoDataAction);
    }

    if (callback) {
      callback(res);
    }
  };
}

export function removeInfoEffect<Info, Payload = any>(url: string, optType?: string) {
  return function* info(
    { callback, payload, handleResData, handleError }: CommonAction<any, Payload, ResData<Info>>,
    { call, put }: EffectsCommandMap
  ) {
    let res: ResData<Info>;

    try {
      res = yield call(request, {
        method: 'DELETE',
        url: url,
        query: payload,
      });
    } catch (errorRes: any) {
      handleError && handleError(errorRes);
      throw errorRes;
    }

    if (handleResData) {
      res = handleResData(res)
    }

    if (optType) {
      const infoDataAction: ReducerInfoDataAction = {
        type: 'infoData',
        optType: optType,
        payload: res.data,
      }
      yield put(infoDataAction);
    }

    if (callback) {
      callback(res);
    }
  };
}

export function getCommonReducer<State extends {[key: string]: any}>() {
  return {
    pageData(state: State, { payload, optType = 'page' }: ReducerPageDataAction) {
      return {
        ...state,
        [optType]: {
          ...state[optType as 'pageData'],
          ...payload,
        },
      };
    },
    infoData(state: State, { payload, optType = 'info' }: ReducerInfoDataAction) {
      return {
        ...state,
        [optType]: {
          ...state[optType],
          ...payload,
        },
      };
    },
    listData(state: State, { payload, optType = 'list' }: ReducerListDataAction) {
      return {
        ...state,
        [optType]: payload,
      };
    },
    primitiveData(state: State, { payload, optType = 'primitive' }: ReducerListDataAction) {
      return {
        ...state,
        [optType]: payload,
      };
    },
  };
}
