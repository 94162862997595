import axios from 'axios';
import { cloneDeep, isEmpty } from 'lodash';
import {parse, compile} from 'path-to-regexp';
import { message } from 'antd';
import {
  CANCEL_REQUEST_MESSAGE,
  DefaultTimeout,
  EErrorType,
  EHttpStatusMesage,
} from './constant';
import qs from 'qs';
import store from 'store';
import * as Sentry from '@sentry/react';
import {onError} from '../plugins/onError';
import { interConfigMap } from '../config/index';
import { hosts } from '../hosts/index';
import urlMaps from '../config/urlMaps';
import { reportException } from './report';

// const getHost = require('@host');

// const domain = getHost()['data-center'];
const domain = hosts['data-center'];

axios.interceptors.response.use((res: any) => {
  const baseUrl = domain;
  console.log("🚀 ~ file: request.ts ~ line 27 ~ axios.interceptors.response.use ~ baseUrl", baseUrl)
  let url = res.config.url?.replace(baseUrl, '') || '';
  const interTypeIndex = url.indexOf('?interType=');
  if (interTypeIndex === -1) {
    url = url.replace(/\?.*?$/, '');
  } else {
    url = url.replace(/(\?interType=[^&]+)(.*?)$/, '$1');
  }
  res.data = res.data || {};
  const resData = res.data;
  const config = interConfigMap[url];
  if (!!config) {
    resData.data = {
      ...resData.data,
      ...config,
    };
  }
  return res;
});

/** 拦截request按照contenttype改变参数格式 */
axios.interceptors.request.use(config => {
  const { headers, data } = config;
  // multipart/form-data 参数使用formdata对象（主要用于表单提交的请求）
  if (headers['content-type'] === 'multipart/form-data') {
    const newData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      newData.append(key, value as any);
    });
    config.data = newData;
  }
  return config;
});

const { CancelToken } = axios;
/**
 *
 *
 * @param {object} { errorMessage, handleErrorOutsideDva, data, modalError }
 * @returns {Promise}
 */
function resultForError({ errorMessage, handleErrorOutsideDva, data, modalError }: any) {
  const { message: msg = '系统发生未知异常，请飞书联系管理员！', code } = data;
  // if (errorMessage) {
  //   message.error(msg);
  // }

  // 进行dva之外的接口错误提示
  handleErrorOutsideDva &&
    onError({
      type: EErrorType.Request,
      code,
      message: msg,
      isModalError: modalError,
    }, () => {});

  /* eslint-disable */
  // 在dva中使用，失败状态的promise，将会抛出异常被dva错误钩子捕获进行错误提示处理
  return Promise.reject({
    type: EErrorType.Request,
    code,
    message: msg,
    isModalError: modalError,
  });
}

/* 判断特殊错误类型（错误展示为modal而不是message） */
function isModalError(code: number) {
  return code < 8000 && code > 7000;
}
const cancelRequest = new Map();

/** 使用正式响应码的服务 */
const formalResponseCodeHost = ['data-center'];

/* 退出登录code值 600201: token无效, 600301: 无此用户 */
const quitCodeMap = [160100005, 600201];
const normalQuitCodeMap = [
  160100005,
  150100004,
  600201,
  600207,
  600230,
  400001001,
  160100008,
  160100009,
  160100010,
];

const sortMap = new Map();

/**
 *处理正式响应码错误
 *
 * @param {number} codeValue
 * @returns {boolean}
 */
function handleFormalHostResponseCodeError(codeValue: any) {
  // 无效token跳转到登录页
  if (normalQuitCodeMap.includes(codeValue)) {
    (window as any).g_app._store.dispatch({
      type: 'app/removeUserInfo',
    });
    // 异常直接跳转, 阻止页面抛出多余的异常message,会导致跳转之后无提示，可以使用计数的方式解决这个问题
    // return Promise.reject();
  }
  return /^15011/.test(codeValue);
}

/**
 *处理旧状态码
 *
 * @param {number} codeValue
 * @returns {boolean}
 */
function handleOldHostResponseCodeError(codeValue: any) {
  // 无效token跳转到登录页
  if (quitCodeMap.includes(codeValue)) {
    (window as any).g_app._store.dispatch({
      type: 'app/removeUserInfo',
    });
    // 异常直接跳转, 阻止页面抛出多余的异常message,会导致跳转之后无提示，可以使用计数的方式解决这个问题
    // return Promise.reject();
  }
  return false;
}

/**
 * 根据不同服务采取不同的code码处理
 *
 * @param {object} options
 * @param {number} codeValue
 * @returns
 */
function distributeError(options: any, codeValue: any) {
  if (formalResponseCodeHost.includes(options.apiPrefix || 'data-center')) {
    return handleFormalHostResponseCodeError(codeValue);
  } else {
    return handleOldHostResponseCodeError(codeValue);
  }
}

/**
 *处理接口错误响应
 *
 * @param {*} options
 * @param {*} response
 * @returns {Promise}
 */
function handleObjectError(options: any, response: any) {
  const { data, status } = response;
  const { handleErrorOutsideDva, errorMessage } = options;
  // 处理http status 状态不为200的接口
  switch (status) {
    case 404:
      return resultForError({
        data: {
          code: status,
          message: EHttpStatusMesage.NotFound,
        },
        handleErrorOutsideDva,
        errorMessage,
        modalError: false,
      });
    case 503:
      return resultForError({
        data: {
          code: status,
          message: EHttpStatusMesage.ServiceUnavailable,
        },
        handleErrorOutsideDva,
        errorMessage,
        modalError: false,
      });
  }

  if (data instanceof Blob) {
    return data
      .text()
      .then(blobString => {
        try {
          return JSON.parse(blobString);
        } catch (error) {
          console.log(error);
        }
      })
      .then(blobData => {
        const modalError = distributeError(options, blobData.code);
        return resultForError({
          data: blobData,
          handleErrorOutsideDva,
          errorMessage,
          modalError: modalError,
        });
      });
  } else {
    const modalError = distributeError(options, data.code);
    return resultForError({
      data,
      handleErrorOutsideDva,
      errorMessage,
      modalError: modalError,
    });
  }
}

/**
 *处理字符串类型的错误响应
 *
 * @param {string} msg
 * @param {object} options
 * @returns {string} msg
 */
function handleStringError(msg: string, options: any) {
  const { handleErrorOutsideDva, errorMessage } = options;
  switch (msg) {
    case 'Timeout error':
      msg = '接口请求时间超过30秒，请稍后重试或飞书联系管理员！';
      break;
    case CANCEL_REQUEST_MESSAGE:
      msg = '接口请求时间超过30秒，请稍后重试或飞书联系管理员！';
      break;
    case 'Network Error':
      msg = '网络连接异常，请检查网络或飞书联系管理员！';
      break;
    default:
      msg = '系统发生未知异常，请飞书联系管理员！';
  }
  return resultForError({
    data: {
      message: msg,
    },
    handleErrorOutsideDva,
    errorMessage,
    modalError: false,
  });
}

/**
 * 获取url第一段路径匹配host，改变apiPrefix，并将第一段用来做标识的路径移除
 *
 * @param {*} url
 * @param {*} config
 * @param {*} options
 * @returns
 */
function proxy(url: string, config: any, options: any) {
  const contexts = Object.keys(config);
  const firstPathnameReg = /^\/([^/]+)\//;
  const context = url.match(firstPathnameReg)?.[1];
  if (!context || !contexts.includes(context)) return url;
  options.apiPrefix = context;
  return url.replace(firstPathnameReg, '/');
}

export default function request(options: any) {
  let {
    data = {},
    url,
    method = 'get',
    query,
    headers = {},
    apiPrefix = 'data-center',
    // errorMessage,
    // handleErrorOutsideDva,
    sort = false,
  } = options;
  options.headers = {
    ...(request as any).headers,
    ...headers,
    // 'login-token': 'eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiIyOThkZmJlNjlkZDA0ZTk2ODdiNDc5MGVkNGI5NDc0OSIsInVzZXJJZCI6IjEwMDAwMDAxNTc2ODIiLCJ1c2VyTmFtZSI6Iuellueri-ahpSIsImlhdCI6MTYyNzk2MjQxOSwiZXhwIjoxNjI3OTk4NDE5fQ.3k8Pf2XyrlfcbuG_fG5OMtuvjUpCtxgzt1ShiZTdT1A'
  };
  const cloneData = cloneDeep(data);
  try {
    let domain = '';
    const urlMatch = url.match(/[a-zA-z]+:\/\/[^/]*/);
    if (urlMatch) {
      [domain] = urlMatch;
      url = url.slice(domain.length);
    } else {
      // const hosts = getHost();
      url = proxy(url, hosts, options);
      domain = hosts[(options.apiPrefix || apiPrefix) as 'data-center'];
    }

    const match = parse(url);
    try {
      url = compile(url)(data);
    } catch (error: any) {
      if (query) {
        url = compile(url)(query);
      } else {
        message.error(error.message);
      }
    }

    for (const item of match) {
      if (item instanceof Object && item.name in cloneData) {
        delete cloneData[item.name];
      }
    }
    url = domain + url;
    // url = '/proxy' + url;
  } catch (e: any) {
    message.error(e.message);
  }
  url =
    method.toLocaleLowerCase() === 'get'
      ? `${url}${isEmpty(cloneData) ? '' : '?'}${qs.stringify(cloneData)}`
      : url;
  if (query) {
    url = `${url}?${qs.stringify(query)}`;
  }
  options.cancelToken = new CancelToken(cancel => {
    cancelRequest.set(Symbol(Date.now()), {
      pathname: window.location.pathname,
      cancel,
    });
  });

  /********************* 时序控制 ************************/
  let order: any;
  if (sort) {
    if (sortMap.has(url)) {
      order = sortMap.get(url);
      sortMap.set(url, ++order);
    } else {
      order = 0;
      sortMap.set(url, order);
    }
  }

  return axios({
    timeout: DefaultTimeout,
    ...options,
    url: url,
  })
    .then(response => {
      if (sort) {
        const latestOrder = sortMap.get(url);
        if (latestOrder !== order) {
          // 丢弃返回值
          return new Promise(() => {});
        }
      }

      // TODO 处理下载接口（下载类型接口需要请求时添加responseType指定response解析方式）

      const { data, headers } = response;
      const { code } = data;
      // let result = {};
      // if (typeof data === 'object') {
      //   result = data;
      //   if (Array.isArray(data)) {
      //     result.list = data;
      //   }
      // } else {
      //   result.data = data;
      // }
      if (code !== 200000) {
        return Promise.reject({
          response,
        });
      }
      /**后台返回token时重写token */
      if (headers['login-token']) {
        store.set('login-token', headers['login-token']);
        request.setHeaders((params: any) => ({
          ...params,
          'login-token': headers['login-token'],
        }));
      }

      /** 接口返回sign-token */
      if(options.url === urlMaps.getToken) {
        const token = data.data['sign-token'];
        store.set('sign-token', token);
        request.setHeaders((params: any) => ({
          ...params,
          'sign-token': token,
        }));
      }
      return Promise.resolve({
        // message: statusText,
        ...data,
      });
    })
    .catch(error => {

      const { response } = error;
      Sentry.captureException(response);

      try {
        const userInfo = store.get('userInfos');

        let exceptionContent = `
          <br/>【pageUrl】: ${location.href}
          <br/>【userAgent】: ${navigator.userAgent}}
          <br/>【userData】: <pre>${JSON.stringify(userInfo)}</pre>
          <br/>【url】: ${options.url}
          <br/>【method】: ${JSON.stringify(options.method)}
          <br/>【header】: ${JSON.stringify(options.headers)}
          <br/>【reqData】: <pre>${JSON.stringify(options.data)}</pre>
          <br/>【query】: ${JSON.stringify(options.query)}
          <br/>【params】: <pre>${JSON.stringify(options.params, null, 2)}</pre>
          <br/>【resData】: <pre>${JSON.stringify(response, null, 2)}</pre>
        `;

        // 返回可能不是json
        const { code, message } = response?.data || {};

        // 除了登录都要上报异常
        if (!normalQuitCodeMap.includes(code)) {
          reportException({
            exceptionCode: code,
            exceptionContent,
            exceptionTitle: message || error.message || '无标题',
            operator: userInfo.userId,
          }, code < 0);
        }
      } catch (error) {
        console.log("🚀 ~ file: request.js ~ line 490 ~ request ~ error", error)
      }

      if (response && response instanceof Object) {
        return handleObjectError(options, response);
      } else {
        return handleStringError(error.message, options);
      }
    });
}

/**
 *
 *设置请求头
 * @param {*} callback @param {} @return {}
 */

request.setHeaders = (callback: any) => {
  const { headers } = request as any;
  const newHeaders = callback(headers);
  if (newHeaders) {
    (request as any).headers = newHeaders;
  }
};

/* 初始化请求头 */
// store.set('login-token', '12345')
request.setHeaders((headers: any) => ({
  ...headers,
  // 'login-token': store.get('login-token'),
  'sign-token': store.get('sign-token'),
  // 'access-source': 'ApiGateway', //调试后端本地可以开启
}));
