import urlMaps from './urlMaps';
import kpiFinanceIndicatorTrend from './json/@@v2@@performance@@kpi@@account@@indicator@@trend.json';
import bbcAgreeAnalysisRegionSaleAmount from './json/@@bbc@@performance@@region@@salse_value.json';
import bbcAgreeAnalysisRegionSaleCount from './json/@@bbc@@performance@@region@@region@@salse_qty.json';
import bbcAgreeAnalysisFirstCategorySales1 from './json/@@bbc@@performance@@firstCategorySales$$interType=1.json';
import bbcAgreeAnalysisFirstCategorySales2 from './json/@@bbc@@performance@@firstCategorySales$$interType=2.json';
import bbcAgreeAnalysisFirstCategorySales3 from './json/@@bbc@@performance@@firstCategorySales$$interType=3.json';
import bbcAgreeAnalysisPackageSale1 from './json/@@bbc@@performance@@packageSale$$interType=1.json';
import bbcAgreeAnalysisPackageSale2 from './json/@@bbc@@performance@@packageSale$$interType=2.json';
import bbcAgreeAnalysisUserSalesFrequencyByWeek from './json/@@bbc@@performance@@userSalesFrequencyByWeek.json';


import newYoBoPerformanceRegionAnalysis1 from './json/@@bbc@@niuyuebao@@performance@@region@@analysis$$type=1.json';
import newYoBoPerformanceRegionAnalysis2 from './json/@@bbc@@niuyuebao@@performance@@region@@analysis$$type=2.json';

import newYoBoPerformanceOutletsAnalysis1 from './json/@@bbc@@niuyuebao@@performance@@outlets@@analysis$$type=1.json';
import newYoBoPerformanceOutletsAnalysis2 from './json/@@bbc@@niuyuebao@@performance@@outlets@@analysis$$type=2.json';

export const interConfigMap = {
  [urlMaps.kpiFinanceIndicatorTrend]: kpiFinanceIndicatorTrend,
  /** bbc履约分析-区域销售分析-含税销售金额 */
  [urlMaps.bbcAgreeAnalysisRegionSaleAmount]: bbcAgreeAnalysisRegionSaleAmount,
  /** bbc履约分析-区域销售分析-销售数量 */
  [urlMaps.bbcAgreeAnalysisRegionSaleCount]: bbcAgreeAnalysisRegionSaleCount,
  /** bbc履约分析-一级分类销售分析 */
  [urlMaps.bbcAgreeAnalysisFirstCategorySales1]: bbcAgreeAnalysisFirstCategorySales1,
  [urlMaps.bbcAgreeAnalysisFirstCategorySales2]: bbcAgreeAnalysisFirstCategorySales2,
  [urlMaps.bbcAgreeAnalysisFirstCategorySales3]: bbcAgreeAnalysisFirstCategorySales3,
  /** bbc履约分析-销售商品分析-套餐商品销售 */
  [urlMaps.bbcAgreeAnalysisPackageSale1]: bbcAgreeAnalysisPackageSale1,
  [urlMaps.bbcAgreeAnalysisPackageSale2]: bbcAgreeAnalysisPackageSale2,
  /** bbc履约分析-用户销售分析-周度用户销售频次分布 */
  [urlMaps.bbcAgreeAnalysisUserSalesFrequencyByWeek]: bbcAgreeAnalysisUserSalesFrequencyByWeek,

  
  /** 牛约堡-项目履约分析-区域销售分析-含税销售金额 */
  [urlMaps.newYoBoPerformanceRegionAnalysis1]: newYoBoPerformanceRegionAnalysis1,
  /** 牛约堡-项目履约分析-区域销售分析-销售数量 */
  [urlMaps.newYoBoPerformanceRegionAnalysis2]: newYoBoPerformanceRegionAnalysis2,
  /** 牛约堡-项目履约分析-区域销售分析-含税销售金额 */
  [urlMaps.newYoBoPerformanceOutletsAnalysis1]: newYoBoPerformanceOutletsAnalysis1,
  /** 牛约堡-项目履约分析-区域销售分析-销售数量 */
  [urlMaps.newYoBoPerformanceOutletsAnalysis2]: newYoBoPerformanceOutletsAnalysis2,

};


// function urlToName(url: string) {
//   return url.replace(/\//g, '@@');
// }
