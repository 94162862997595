import axios from 'axios';
import { ENV } from '../hosts';

export interface ReportOption {
  /** 异常码 */
  exceptionCode?: string;
  /** 异常标题，必填，非空 */
  exceptionTitle?: string;
  /** 异常内容 */
  exceptionContent?: string;
  /** 异常级别 0 通知 3 极其严重  2 严重  1 一般  4灾难（打电话） */
  exceptionLevel?: number;
  /** 0,  异常类型：1 BSF、1后端、2前端、3业务、4运维、5DBA、6其他 */
  exceptionType?: number;
  /** 操作人 */
  operator?: string;
  /** 产品名称 */
  productName?: string;
  /**  后端项目名 */
  projectBeName?: string;
  /**  前端项目名 */
  projectFeName?: string;
  /** 产品名称 */
  productId?: number;
  /** 影响范围 */
  bizScope?: string;
}

export async function reportException(reportOptions: ReportOption, isReportFeishu: boolean = true) {
  const env = ENV;
  let url = 'http://dev.api.b2bcsx.com:8000/exception/exception/reportException';

  if (env === 'production') {
    url = 'http://api.freshfood.cn/exception/exception/reportException';
  }


  const reqData: ReportOption = {
    exceptionCode: '150620000',
    exceptionContent: '',
    exceptionLevel: 0,
    exceptionTitle: '',
    exceptionType: 6,
    operator: '',
    productName: '外部数据服务',
    projectBeName: '',
    projectFeName: '',
    productId: 67,
    bizScope: '外部数据服务',
    ...reportOptions,
  };

  try {
    const win = window as any;
    if (win.aegis) {
      win.aegis.error(JSON.stringify(reqData));
    }
  } catch (error) {
    console.log("🚀 ~ file: report.ts ~ line 57 ~ reportException ~ error", error);
  }

  if (isReportFeishu) {
    try {
      axios({
        url,
        method: 'POST',
        data: reqData,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .catch(error => {
          console.log('🚀 ~ file: report.ts ~ line 44 ~ report ~ error', error);
        })
        .then(response => {
          console.log('🚀 ~ file: report.ts ~ line 48 ~ report ~ response', response);
        });
    } catch (error) {
      console.log('上报自己出现异常不能影响业务', error);
    }
  }

}
