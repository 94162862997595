class UrlMaps {
  //公共

  getToken = '/common/token';
  userAuthList = '/v2/user/role/getOperationById/:userId';

  //公共接口
  /** 获取客户集合 */
  commonCustomerCustomerList = '/bbc/performance/customer';
  /** 获取公司集合 */
  commonCustomerCompanyList = '/bbc/performance/company';

  //KPI管理

  kpiFinanceIndicatorTrend = '/v2/performance/kpi/account/indicator/trend';

  /** BBC履约分析 */
  /** bbc履约分析-概览 */
  bbcAgreeAnalysisOverview = '/bbc/performance/overview';
  /** bbc履约分析-概览 */
  bbcAgreeAnalysisOverviewV2 = '/bbc/performance/overview/v2';
  // 下钻表格
  bbcPerformanceVoucherExchangeDetail = '/bbc/performance/voucher/exchange/detail'

  /** bbc履约分析-区域销售分析-含税销售金额 */
  bbcAgreeAnalysisRegionSaleAmount = '/bbc/performance/region/salse_value';
  /** bbc履约分析-区域销售分析-销售数量 */
  bbcAgreeAnalysisRegionSaleCount = '/bbc/performance/region/salse_qty';
  /** bbc履约分析-一级分类销售分析 */
  bbcAgreeAnalysisFirstCategorySales = '/bbc/performance/firstCategorySales';
  bbcAgreeAnalysisFirstCategorySales1 = '/bbc/performance/firstCategorySales?interType=1';
  bbcAgreeAnalysisFirstCategorySales2 = '/bbc/performance/firstCategorySales?interType=2';
  bbcAgreeAnalysisFirstCategorySales3 = '/bbc/performance/firstCategorySales?interType=3';
  /** bbc履约分析-销售商品分析-套餐商品销售 */
  bbcAgreeAnalysisPackageSale = '/bbc/performance/packageSale';
  bbcAgreeAnalysisPackageSale1 = '/bbc/performance/packageSale?interType=1';
  bbcAgreeAnalysisPackageSale2 = '/bbc/performance/packageSale?interType=2';
  /** bbc履约分析-销售商品分析-top20商品 */
  bbcAgreeAnalysisTop20SaleGoodsPage = '/bbc/performance/top20SalesGoods';
  /** bbc履约分析-用户销售分析-周度用户销售频次分布 */
  bbcAgreeAnalysisUserSalesFrequencyByWeek = '/bbc/performance/userSalesFrequencyByWeek';
  /** bbc履约分析-用户销售分析-含税销售金额TOP50用户 */
  bbcAgreeAnalysisTop50SalesUserPage = '/bbc/performance/top50SalesUser';

  
  /** 牛约堡-履约分析-区域下拉 */
  newYoBoPerformanceAreaDropbox = '/bbc/niuyuebao/performance/area/dropbox';
  /** 牛约堡-履约分析-概览 */
  newYoBoPerformanceOverview = '/bbc/niuyuebao/performance/overview';
  /** 牛约堡-履约分析-区域销售分析 type:<1:含税销售金额, 2:销售数量> */
  newYoBoPerformanceRegionAnalysis1 = '/bbc/niuyuebao/performance/region/analysis?interType=1';
  newYoBoPerformanceRegionAnalysis2 = '/bbc/niuyuebao/performance/region/analysis?interType=2';

  /** 牛约堡-履约分析-门店销售TOP20 type:<1:含税销售金额, 2:销售数量> */
  newYoBoPerformanceOutletsAnalysis1 = '/bbc/niuyuebao/performance/outlets/analysis?interType=1';
  newYoBoPerformanceOutletsAnalysis2 = '/bbc/niuyuebao/performance/outlets/analysis?interType=2';

  bbcNiuyuebaoPerformanceOutletsOrderDetail = '/bbc/niuyuebao/performance/outlets/order/detail';
  bbcNiuyuebaoPerformanceGoodsDetail = '/bbc/niuyuebao/performance/goods/detail';
  bbcNiuyuebaoPerformanceGoodsList = '/bbc/niuyuebao/performance/goods/list';
  bbcNiuyuebaoPerformanceGoodsInventory = '/bbc/niuyuebao/performance/goods/inventory';
  bbcNiuyuebaoPerformanceGoodsStock = '/bbc/niuyuebao/performance/goods/stock';

}

export default new UrlMaps();
