import React, { Suspense } from "react";
import { Layout } from "antd";
import { getRouterData } from "../config/router";
import { BrowserRouter, Redirect, Route, Switch } from "dva/router";
import { RaLoading, setBaseUrl, setErrorTip, setRequest } from "radish";
import request from "../utils/request";
import { connect } from "dva";
import { ModelProps } from "../utils/common";
import { AppModel } from "../models/App";
import ServerError from "../pages/ServerError/ServerError";
import store from 'store';

const { Content } = Layout;

interface IProps {

}

interface IState {

}

const routerData = getRouterData();

setBaseUrl('');
setErrorTip(() => {}); // 关闭组件库接口提示
setRequest(options => {
  // 组件库接口提示使用项目request的提示
  options.handleErrorOutsideDva = true;
  return request(options);
});

interface IPureProps {}

interface IProps extends ModelProps<AppModel>, IPureProps {}

@connect(({app, loading}: IProps) => ({app, loading}))
export class Layouts extends React.Component<any, IState> {
  state = {
    tokenError: false,
  }

  componentDidMount() {
    const urlInstance = new URL(window.location.href);
    const data = urlInstance.searchParams.get('data');
    // console.log("🚀 ~ file: index.tsx ~ line 36 ~ Layouts ~ componentDidMount ~ data", data)
    try {

      const userInfoStr = window.atob(data as string);
      const userInfos = JSON.parse(userInfoStr);
      store.set('userInfos', userInfos);
      const win = window as any;

      if(win.aegis) {
        win.aegis.setConfig({
          uin: `${userInfos.appid}${userInfos.userId}`,
        });
      }

    } catch (error) {
      console.log("🚀 ~ file: index.tsx ~ line 50 ~ Layouts ~ componentDidMount ~ error", error)

    }
    this.props.dispatch({
      type: 'app/getToken',
      payload: {data},
      handleError: (res: any) => {
        this.setState({tokenError: true});
        console.log("🚀 ~ file: index.tsx ~ line 52 ~ Layouts ~ componentDidMount ~ res", res)
      }
    });
  }

  render() {
    const { app, loading } = this.props;
    const { tokenError } = this.state;
    if(!app.tokenInfo['sign-token']) {
      if(tokenError) {
        return <ServerError></ServerError>
      } else {
        return <div style={{display: 'flex', justifyContent: 'center', paddingTop: 200}}>
          <RaLoading loading={loading.effects["app/getToken"]}></RaLoading>
        </div>;
      }
    }

    return (
      <Layout>
        {/* <Header style={{background: '#fff'}}>
          {routerData.map(item => <a href={item.url}> {item.meta.title} </a>)}
        </Header> */}
        <Content>
           <BrowserRouter>
            <Suspense fallback={(
              <div style={{display: 'flex', justifyContent: 'center', paddingTop: 200, background: '#fff'}}>
                <RaLoading loading={true}></RaLoading>
              </div>
            )}>
              <Switch >
                {
                  routerData.map(item => {
                    return (
                      <Route
                        path={item.url}
                        key={item.url}
                        component={item.component}
                      />
                    );
                  })
                }
                <Redirect
                  to={{
                    pathname: '/404',
                  }}
                />
              </Switch>
            </Suspense>
          </BrowserRouter>
        </Content>
        {/* <Footer>Footer</Footer> */}
      </Layout>
    );
  }
}
