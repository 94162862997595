export const ROLE_TYPE = {
  ADMIN: 'admin',
  DEFAULT: 'admin',
  DEVELOPER: 'developer',
};

export const DefaultTimeout = 30000;

export const CANCEL_REQUEST_MESSAGE = `timeout of ${DefaultTimeout}ms exceeded`;

/**
 * 报表中默认数据条数
 * @type {number}
 */
export const DEFAULT_PAGE_SIZE = 30;

/** 自助查询商品统计类型 */
export const SELF_CHECK_TYPE = {
  BIG_CUSTOMER: '8',
  MALL: '7',
};

/** 自助查询商品统计类型 */
export enum ESelfCheckType {
  ORDER_STATISTICS_MAJOR_CUSTOMER = '8',
  ORDER_STATISTICS_MALL = '7',
}

/** 下拉框选项是否包含全部 */
export const SELECT_OPTIONS_TYPE = {
  HAS_ALL: '1',
  OMIT_ALL: '2',
};

export const CUSTOMER_CODE_TYPE = {
  BIG_CUSTOMER: '1',
  MALL: '2',
};

/**
 * 系统管理公告平台数据中心的渠道标识(首页通知模块有用到)
 */
export const NOTICE_PROJECT_CODE: string = 'DATACENTER';

/**
 * 数据中心在任务中心的渠道标识
 */
export const ProjectCodeForTaskCenter: string = 'csx-b2b-data-center-v2-schedul';
// export const TASK_CENTER_CODE: string = 'DC';

export const TaskCenterSysCode: string = 'TASK-SCHEDULING';

export enum ENoticeStatus {
  Saved = 1,
  Release,
  Delete,
}
export enum EMenuTypes {
  Directory = 0,
  Page,
  Tab = 2,
}

export enum EErrorType {
  Request,
}

export enum EHttpStatusMesage {
  NotFound = '接口未找到，请稍候重试',
  ServiceUnavailable = '网关服务异常，请稍候重试',
}
